import React from "react"
import { Helmet } from "react-helmet"
import { OutboundLink } from "gatsby-plugin-google-gtag"

import { Link, graphql } from "gatsby"
import Logo from "../assets/svgs/logo.svg";

const Header = () => {
    
    return (
      <header className="main_header">
        <div className="header_inner_wrapper">
          <div className="top_nav">
            <div className="logo">
              <Link to="/">
                <Logo />
              </Link>
            </div>
          </div>
        </div>
      </header>
    );
};

const Content = ({post}) => {
    return (
        <div id="content_outer_wrapper">
        <div id="content_inner_wrapper">
          <div id="content" data-content-context="job-opening">
            <header class="job_opening_title row">
              <div class="col_12 center">
                <h1 class="knockout">{post.frontmatter.title.toUpperCase()}</h1>
                <p class="job_opening_location">İstanbul'da bize katılın</p>
              </div>
              <div class="col_12 center">
                <h4>Bu pozisyona <a href="#job_application">başvur.</a></h4>
              </div>
            </header>
            <section id="rotator" class="job_description row">
              <div class="col_9">
                <div class="job_description_intro">
                    <div dangerouslySetInnerHTML={{ __html: post.html }} />
                </div>
              </div>
            </section>
            <section id="job_application" class="job_application full_bleed">
              <div class="section_inner_wrapper row">
                <section id="inclusion" class="inclusion row">
                  <div class="row col_9">
                    <label>
                    Takyon da birlikte büyümek için tutkuya, yeni şeylerden denemek için cesarete ve dürüstlüğe sahip olan insanlar arıyoruz. Deneyiminiz, iş tanımına tam olarak uymasa bile mutlaka başvurmanızı teşvik ediyoruz. Becerileriniz ve tutkunuzun sizi öne çıkaracağına inanıyoruz. Özellikle kariyerinizde bazı olağanüstü gelişmeler ve dönüşler varsa lütfen bunu bize aktarın. Takyon'da, farklı bakış açılarına, titizlikle düşünebilen ve varsayımlara meydan okumaktan korkmayan insanlara ihtiyacımız var. İş başvurusu yaparken ve sonrasında, gerekirse konaklama ve ulaşım masraflarınız için destekler sağlıyoruz. Bu konu hakkında daha fazla bilgi almak için iletişime geçebilirsiniz. Bize katılın.
                    </label>
                    <br /><br />
                  </div>
                </section>
                <div class="row">
                  <h3>Demek bizimlesin!</h3>
                  <OutboundLink href={post.frontmatter.form_data} target="_blank" className="applyButton">Başvur</OutboundLink>
                </div>
                
              </div>
            </section>
          </div>
        </div>
      </div>
    )
}


export default function JobPage({ data }) {
  const post = data.markdownRemark
  return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Takyon Jobs - {post.frontmatter.title}</title>
        </Helmet>
        <Header/>
        <Content post={post} />
      </div>
  )
}
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        form_data
      }
    }
  }
`
